<div class="row no-gutters justify-content-end">
  <div class="col-sm-3">
    <div class="form-group">
      <label for="category" class="font-weight-bold">Category</label>
      <select id="category" class="form-control" name="category" #category
        (change)="filterProducts($event, currentPage, brand.value, category.value, desc.value)">
        <option *ngFor="let category of categories" value="{{category._id}}">
          {{category.name}}
        </option>
      </select>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="form-group">
      <label for="brand" class="font-weight-bold">Brand</label>
      <select id="brand" class="form-control" name="brand" #brand
        (change)="filterProducts($event, currentPage, brand.value, category.value, desc.value)">
        <option *ngFor="let brand of brands" value="{{brand._id}}">
          {{brand.name}}
        </option>
      </select>
    </div>
  </div>

  <div class="col-sm-3 pt-2">
    <label for="search"></label>
    <input id="search" type="text" class="form-control" name="desc" #desc
      (keyup.enter)="filterProducts($event, currentPage, brand.value, category.value, desc.value)"
      placeholder="Search..">
  </div>
</div>

 <div class="table-responsive-md w-100">
  <table class="table table-bordered color-white">
    <thead>
      <tr>
        <th *ngIf="isSelectMode"></th>
        <th></th>
        <th class="hide">Name</th>
        <th class="hide">Description</th>
        <th class="hide">Status</th>
        <th *ngIf="!isSelectMode">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let product of products" (click)="selectProduct(product)">
        <td class="align-middle" *ngIf="isSelectMode">
          <input type="checkbox" class="form-control-lg" [ngModel]="product.selected">
        </td>
        <td class="align-middle"><img src="{{product.image}}" alt="avatar of image" class="rounded imageStyle">
        </td>
        <td class="align-middle hide">{{product.name}}</td>
        <td class="align-middle hide">{{product.description}}</td>
        <td class="align-middle hide">{{product.isActivated ? 'Activated' : 'Deactivated'}}</td>
        <td class="align-middle" *ngIf="!isSelectMode">
          <div class="dropdown dropleft">
            <button class="btn btn-light btn-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <fa-icon icon="ellipsis-h"></fa-icon>
            </button>
            <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
              <button class="btn btn-block btn-warning" (click)="showEditProduct(product)">
                <span class="text-color-white">Edit</span>
              </button>
              <button class="btn btn-block m-0 btn-danger" (click)="showConfirmDialog(product._id)">
                <span>Delete</span>
              </button>
              <button class="btn btn-block m-0 btn-primary" (click)="activateDeactivateProduct(product)">
                <span>{{product.isActivated ? 'Deactivate' : 'Activate'}}</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

 <nav aria-label="..." class="table-responsive">
  <ul class="pagination flex-wrap justify-content-end">
    <li class="page-item" [ngClass]="{'disabled': !productsList || (productsList && !productsList.hasPrevPage)}">
      <a class="page-link cursor"
        (click)="currentPage = currentPage - 1; filterProducts($event, currentPage, brand.value, category.value, desc.value)">
        Prev
      </a>
    </li>
    <li *ngFor="let page of totalPages | slice:((currentPage > 2) ? currentPage-3 : 0) : ((totalPages.length > currentPage+4) ? currentPage+4 : totalPages.length)"
      class="page-item" [ngClass]="{'active': page === currentPage}">
      <a class="page-link cursor"
        (click)="currentPage = page; filterProducts($event, currentPage, brand.value, category.value, desc.value)">
        {{page}}
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': !productsList || (productsList && !productsList.hasNextPage)}">
      <a class="page-link cursor"
        (click)="currentPage = currentPage + 1; filterProducts($event, currentPage, brand.value, category.value, desc.value)">
        Next
      </a>
    </li>
  </ul>
</nav>

<app-create-edit-product [product]="product" (createdProduct)="filterProducts($event, currentPage, brand.value, category.value, desc.value)"></app-create-edit-product>
<app-confirm-dialog></app-confirm-dialog>
