import { Component, OnInit } from '@angular/core';
import { Event, EventsList } from '../../../models';
import { DateRange } from '../../../models/Event';
import { EventService, ConfirmDialogService } from '../../../services';
import { GlobalVariables } from '../../../helpers';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  eventsList: EventsList;
  events: Event[];
  event: Event;
  currentPage = 1;
  totalPages: number[];

  constructor(private eventService: EventService,
              private globalVariables: GlobalVariables,
              private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    this.globalVariables.navBarTitle = 'Events';
    this.getEvents(this.currentPage);
  }

  getEvents(page: number, desc?: string) {
    this.eventService.getEvents(page, 10, desc).subscribe((value) => {
      this.eventsList = value;
      this.totalPages = new Array<number>(value.totalPages);
      value.events.forEach((event) => {
        event.date.from = new Date(event.date.from);
        if (event.date.to) { event.date.to = new Date(event.date.to); }
      });
      this.events = value.events;
    });
  }

  filterEvents($event, page, desc) {
    this.getEvents(page, desc);
  }

  formatDate(date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  showConfirmDialog(id: string) {
    this.confirmDialogService.confirmThis('Confirm', 'Are you sure that you want to delete this event?', 'Yes', 'No',
    () => {
      this.deleteEvent(id);
    }, () => {
    });
  }

  deleteEvent(id: string) {
    this.eventService.deleteEvent(id).subscribe( (value) => {
      this.events = this.events.filter((event) => {
        return event._id !== id;
      });
    });
  }

  showCreateEvent() {
    this.event = new Event('', '', '', new DateRange(new Date()), '', '', '', null, null, null);
    $('#eventModal').modal('show');
  }

  showEditEvent(event: Event) {
    this.event = event;
    $('#eventModal').modal('show');
  }
}
