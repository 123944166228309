<div class="row mt-4">
  <div class="col text-right">
    <button type="button" class="glosho-button btn btn-lg" (click)="showCreateBrand()">New Brand</button>
  </div>
</div>
  <div class="row no-gutters justify-content-end">
  <div class="col-sm-3">
    <label for="brand-search"></label>
    <input id="brand-search" type="text" class="form-control" name="desc" #desc
      (keyup.enter)="filterBrands($event, currentPage,  desc.value)" placeholder="Search..">
  </div>
</div>

<div class="table-responsive-md w-100">
  <table class="table table-bordered color-white">
    <thead>
      <tr>
        <th>Logo</th>
        <th class="hide">Name</th>
        <th class="hide">Display Name</th>
        <th class="hide">Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let brand of brands">
        <td class="table__image"><img src="{{brand.logo}}" alt="logo of brand" class=" rounded imageStyle">
        </td>
        <td class="align-middle hide">{{brand.name}}</td>
        <td class="align-middle hide">{{brand.displayName}}</td>
        <td class="align-middle hide">{{brand.isActivated ? 'Activated' : 'Deactivated'}}</td>
        <td class="align-middle">
          <div class="dropdown dropleft">
            <button class="btn btn-light btn-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <fa-icon icon="ellipsis-h"></fa-icon>
            </button>
            <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
              <button class="btn btn-block m-0 btn-warning" (click)="showEditBrand(brand)">
                <span class="text-color-white">Edit</span>
              </button>
              <button class="btn btn-block m-0 btn-danger" (click)="showConfirmDialog(brand._id)">
                <span>Delete</span>
              </button>
              <button class="btn btn-block m-0 btn-primary" (click)="activateDeactivateBrand(brand)">
                <span>{{brand.isActivated ? 'Deactivate' : 'Activate'}}</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
  <nav aria-label="..." class="table-responsive">
  <ul class="pagination flex-wrap justify-content-end">
    <li class="page-item" [ngClass]="{'disabled': !brandsList || (brandsList && !brandsList.hasPrevPage)}">
      <a class="page-link cursor"
        (click)="currentPage = currentPage - 1; filterBrands($event, currentPage, desc.value)">
        Prev
      </a>
    </li>
    <li *ngFor="let page of totalPages; let i = index" class="page-item" [ngClass]="{'active': i == currentPage - 1}">
      <a class="page-link cursor" (click)="currentPage = i + 1; filterBrands($event, currentPage, desc.value)">
        {{i+1}}
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': !brandsList || (brandsList && !brandsList.hasNextPage)}">
      <a class="page-link cursor"
        (click)="currentPage = currentPage + 1; filterBrands($event, currentPage, desc.value)">
        Next
      </a>
    </li>
  </ul>
</nav>
  <app-create-edit-brand [brand]="brand" (createdBrand)="filterBrands($event, currentPage, desc.value)"></app-create-edit-brand>
  <app-confirm-dialog></app-confirm-dialog>
