<div class="row mt-4">
  <div class="col text-right">
    <button type="button" class="glosho-button btn btn-lg" (click)="showCreateCategory()">New category</button>
  </div>
</div>

<div class="table-responsive-md  w-100">
  <table class="table table-bordered color-white">
    <thead>
      <tr>
        <th></th>
        <th class="hide">Name</th>
        <th class="hide">Description</th>
        <th class="hide">Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let category of categories">
        <td class="table__image"><img src="{{category.portraitImage}}" alt="avatar of portrait image" class="rounded imageStyle">
        </td>
        <td class="align-middle hide">{{category.name}}</td>
        <td class="align-middle hide">{{category.description}}</td>
        <td class="align-middle hide">{{category.isActivated ? 'Activated' : 'Deactivated'}}</td>
        <td class="align-middle">
          <div class="dropdown dropleft">
            <button class="btn btn-light btn-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <fa-icon icon="ellipsis-h"></fa-icon>
            </button>
            <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
              <button class="btn btn-block m-0 btn-warning" (click)="showEditCategory(category)">
                <span class="text-color-white">Edit</span>
              </button>
              <button class="btn btn-block m-0 btn-danger" (click)="showConfirmDialog(category._id)">
                <span>Delete</span>
              </button>
              <button class="btn btn-block m-0 btn-primary" (click)="activateDeactivateCategory(category)">
                <span>{{category.isActivated ? 'Deactivate' : 'Activate'}}</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<nav aria-label="..." class="table-responsive">
  <ul class="pagination flex-wrap justify-content-end">
    <li class="page-item" [ngClass]="{'disabled': !categoriesList || (categoriesList && !categoriesList.hasPrevPage)}">
      <a class="page-link cursor" (click)="currentPage = currentPage - 1; filterCategories($event, currentPage)">
        Prev
      </a>
    </li>
    <li *ngFor="let page of totalPages; let i = index" class="page-item" [ngClass]="{'active': i == currentPage - 1}">
      <a class="page-link cursor" (click)="currentPage = i + 1; filterCategories($event, currentPage)">
        {{i+1}}
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': !categoriesList || (categoriesList && !categoriesList.hasNextPage)}">
      <a class="page-link cursor" (click)="currentPage = currentPage + 1; filterCategories($event, currentPage)">
        Next
      </a>
    </li>
  </ul>
</nav>

<app-create-edit-category [category]="category" (createdCategory)="getCategories(currentPage)"></app-create-edit-category>
<app-confirm-dialog></app-confirm-dialog>
