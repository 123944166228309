<div class="sidenav fixed-left" [class.collapsed]="collapsed">
  <div class="brand-logo__container">
    <button class="sidenav__close" (click)="closeSidebar()">
      <img  class="sidenav__close-icon" src="../../../../assets/images/x-mark-32.png" alt="close sidenav">
    </button>
    <h1 class="brand-logo">Global Showcases</h1>
    <form class="search">
      <button type="button" class="search__button">
        <img class="search__icon" src="../../../../assets/images/magnifying-glass.png" alt="search icon">
      </button>
      <label for="search"></label>
      <input type="text" id="search" class="search__input" placeholder="Enter product ID, title, brand">
    </form>
  </div>
  <nav class="navbar pt-0 pl-0 pr-0">
    <!-- Links -->
    <ul class="navbar-nav link-list" (click)="closeSidebar()">
      <!-- <li class="nav-item link-list__item">
      <a class="nav-link p-3 link-list__link" routerLinkActive="active">Dashboard</a>
    </li>-->
      <li class="nav-item link-list__item">
        <a class="nav-link p-3 link-list__link" routerLink="brands" routerLinkActive="active">Brands</a>
      </li>
      <li class="nav-item link-list__item">
        <a class="nav-link p-3 link-list__link" routerLink="categories" routerLinkActive="active">Categories</a>
      </li>
      <li class="nav-item link-list__item">
        <a class="nav-link p-3 link-list__link" routerLink="content-blocks" routerLinkActive="active">Content Blocks</a>
      </li>
      <li class="nav-item link-list__item">
        <a class="nav-link p-3 link-list__link" routerLink="events" routerLinkActive="active">Events</a>
      </li>
      <li class="nav-item link-list__item">
        <a class="nav-link p-3 link-list__link" routerLink="products" routerLinkActive="active">Products</a>
      </li>
      <li class="nav-item link-list__item">
        <a class="nav-link p-3 link-list__link" routerLink="stores" routerLinkActive="active">Stores</a>
      </li>
      <li class="nav-item link-list__item">
        <a class="nav-link p-3 link-list__link" routerLink="reports" routerLinkActive="active">Reports</a>
      </li>
      <!-- <li class="nav-item link-list__item">
      <a class="nav-link p-3 link-list__link" href="#" routerLinkActive="active">Campaigns</a>
    </li>
    <li class="nav-item link-list__item">
      <a class="nav-link p-3 link-list__link" href="#" routerLinkActive="active">Clients</a>
    </li>
    <li class="nav-item link-list__item">
      <a class="nav-link p-3 link-list__link" href="#" routerLinkActive="active">Analytics</a>
    </li>
    <li class="nav-item link-list__item">
      <a class="nav-link p-3 link-list__link" href="#" routerLinkActive="active">Preview</a>
    </li>
    <li class="nav-item link-list__item">
      <a class="nav-link p-3 link-list__link" href="#" routerLinkActive="active">Rewards</a>
    </li>
    <li class="nav-item link-list__item">
      <a class="nav-link p-3 link-list__link" href="#" routerLinkActive="active">Retail Services</a>
    </li>
    <li class="nav-item link-list__item">
      <a class="nav-link p-3 link-list__link" href="#" routerLinkActive="active">Account</a>
    </li> -->
    </ul>
  </nav>
</div>
